.category-onlypic{
  :global{
    .grid-area{
      --gap-horizontal: 0.5rem;
      --gap-vertical: 0.5rem;
    }
  }
}

.category{
  :global{
    .grid-area{
      --gap-horizontal: 0.5rem;
      --gap-vertical: 1.25rem;
    }
  }
}