.slots-category{
  :global{
    .grid-area{
      --gap-vertical: 0.5rem;
    }
  }
}

.sports-button{

  > span{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .25rem;
  }
}