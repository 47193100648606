:root {
  --main-color: red;
  --T1: #fff;
  --T2: #c3c9d2;
  --T3: #98a3b7;
  --T4: #5b5f67;
  --card: #323738;
  --BG: #232626;
  ---Line: rgba(7, 6, 6, 0.1);

  /* 空图片占位颜色，同样依据设计稿命名 */
  --image: #3b3f49;
  /* 品牌色 */
  --1: #ff6900;
  /* 辅助色，少见，如果这样的辅助色用该辅助色 */
  --2: #008c0e;
  /* 某些按钮的渐变色，主要依据品牌色变化而变化 */
  --button-big: linear-gradient(180deg, #ff6900 0%, #cc5400 100%);

  --button-s: #373b44;
  /* 订单内Won下面的颜色、结果页Congratulation颜色（暂定这两个） */
  --order-light: var(--primary);
  --button-small: #373b44;
  --button: #314256;

  /* 其他自定义统一变量 */
  --linear-filter: linear-gradient(180deg, #212329 0%, #16181b 100%);
  --line: #404550;
  --T2-dark: rgba(255, 255, 255, 0.7);
  --game-line: #505C6B;
  --dark-bg: #11161D;
}

:root .invitation-rule {
  --table-title-color: #e75300;
  --table-odd-color: #ffe4d9;
  --table-even-color: #ffd1b7;
}

:root .agent {
  --grey-area: rgba(255, 255, 255, 0.05);
}

:root .home-page {
  --f1: #ff8733;
}
