.amount {
  padding: 1rem 1.25rem;
  background: url("~@/assets/imgs/amount-bg.png");
  background-size: 100% 100%;
  color: #fff;
  font-family: Inter;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .amountleft {
    .amountTitle {
      display: flex;
      align-items: center;
      .amountText {
        font-size: .75rem;
        font-weight: 500;
      }
      .amountIcon {
        width: 1rem;
        height: 1rem;
        margin-right: .5rem;
      }
      .eyesIcon {
        width: .75rem;
        height: .75rem;
        margin-left: .25rem;
      }
    }
  }
  .refreshIcon {
    width: 2rem;
    height: 2rem;
  }
  .amountValue {
    font-family: DIN;
    font-size: 1.25rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: .75rem;
    .accrowIcon {
      width: .84375rem;
      height: .9375rem; 
      margin-left: .75rem;
    }
  }
}
.headbg {
  background: url("~@/assets/svgs/me-bg.07.png");
  background-size: cover;
  //background-position-y: -40px;
  height: var(--height, 16.25rem);
  width: 100%;
  right: 0;
  position: absolute;
  z-index: -1;
  pointer-events: none;
  top: 0;
}
.userContianer {
  padding: 1.25rem 1.25rem 0;
  margin-bottom: 1rem;
}
.walletContainer {
 padding: 0 1.25rem;
}
.borderbg {
  border-radius: 1.25rem 1.25rem 0px 0px;
  background: linear-gradient(180deg, #181A1F 0%, rgba(40, 43, 50, 0.00) 100%);
  padding: .75rem;
  margin-top: -0.4375rem;
}
.card {
  .cardItem,.cardItem2 {
    padding: .5rem .75rem;
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
    align-items: center;
    background: var(--card, var(--card));
    height: 100%;
    .icon {
      width: 2.5rem;
      flex-shrink: 0;
      height: 2.5rem;
    }
    .title {
      color: var(--T1, #FFF);
      text-align: center;
      font-size: .875rem;
      font-style: normal;
      font-weight: 500;
      margin-top: .5rem;
    }
    .desc {
      color: var(--T2, #93979F);
      text-align: center;
      font-size: .625rem;
      margin-top: .25rem;
      font-weight: 500;
    }
  }
  .cardItem2 {
    padding: .75rem;
    display: flex;
    border-radius: .5rem;
    background: var(--card, var(--card));
    align-items: center;
    flex-direction: row;
    min-height: 4.5rem;
    gap: 1rem;
    .title {
      text-align: left;
      margin-top: 0;
    }
    .desc{
      text-align: left;
    }
  }
}
