@tailwind base;
@tailwind components;
@tailwind utilities;
:root{
  --max-screen-width: 500px;
}

/* 或者仅针对具有 tabindex 的元素 */
[tabindex]:focus {
  outline: none;
}
:root:root{
  --adm-color-primary: var(--1);
  --adm-color-background: var(--BG);
  --adm-mask-z-index: 10000;
  --adm-popup-z-index: 10010;
}

:root .adm-button{
  --adm-button-border-width: 0;
}

:root .adm-swiper{
  .adm-page-indicator{
    --dot-size: .25rem;
    --active-dot-size: 1.75rem;
    --dot-border-radius: .25rem;
    --dot-spacing: .5rem;
  }
}

:root .adm-image{
  --adm-color-fill-content: var(--image);

  img[src=""], img:not([src]){
    opacity: 0;
  }
}

:root .adm-center-popup{
  --adm-center-popup-min-width: 0;
  --adm-center-popup-max-width: 100vw;
}

:root .adm-toast-main{
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 18.5rem;
  width: fit-content;
  min-width: 0;
  .adm-toast-icon{
    margin-bottom: 0;
  }
}

.toast-mask{
  background: rgba(0, 0, 0, 0.3)!important;
}

.bg-linear-filter{
  background: var(--linear-filter);
}

html,
body {
  height: 100vh;
  width: 100%;
  background: var(--BG);
  /* 文本不好默认，最好自行覆盖相应的文字颜色 */
  color: var(--T1);
  max-width: var(--max-screen-width);
  margin: 0 auto;
  font-weight: 500;
  font-family: 'Inter';
}
html{
  font-size: calc(16vw / 3.75);
}

@media (min-width: 500px) {
  html,
  body {
    font-size: calc(16 / 375 * var(--max-screen-width));
  }
}

